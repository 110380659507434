import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

document.addEventListener("DOMContentLoaded", function () {
  const menuButton = document.querySelector('button[aria-label="Abrir menú"]');
  const closeButton = document.querySelector(
    'button[aria-label="Cerrar menú"]',
  );
  const mobileMenu = document.querySelector("#mobile-menu");

  menuButton.addEventListener("click", function () {
    mobileMenu.classList.toggle("hidden");
  });

  closeButton.addEventListener("click", function () {
    mobileMenu.classList.toggle("hidden");
  });
});
